import React from 'react'
import Styles from '../styles/content.module.scss'

const Content = ({ children, disqus }) => {
  return (
    <div className={Styles.SectionWrapper}>
      <div className={Styles.Section} role="main">
        {children}
        <footer style={{ maxWidth: '800px', color: '#707070' }}>
          {/* <hr />
          <p>
            Want to make changes or updates to this documentation? Suggest edits
            on our GitHub repo at:&nbsp;
            <a
              href="https://github.com/Losant/losant-docs"
              rel="noopener noreferrer"
              target="_blank"
            >
              https://github.com/Losant/losant-education
            </a>
            .
          </p> */}
        </footer>
      </div>
    </div>
  )
}

export default Content
